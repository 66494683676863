.gm-croppedLabel-table{
    padding-top: 10px; 
    margin: auto;
    padding-left: 10px; 
}


.gm-croppedLabel-table span {
    padding-top: 2px; 
    padding-bottom: 2px;
    font-size: 12px;
    
}



