.two-icons {
  display: flex;
  flex-direction: row;
}

.fed-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fed-main svg {
  width: 50px;
  height: 50px;
  margin: auto;
  margin-bottom: 20px;
}

.fed-icons-parent {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.fed-icons {
  display: flex;
  flex-direction: row;
  align-items: space-around;
  justify-content: space-around;
}

.fed-icons img {
  width: 50px;
  height: auto;
  margin: auto;
}
