.gm-postcard-print-header {
  border-top: 4px solid var(--print-navbar-border-color-top);
  border-bottom: 4px solid var(--print-navbar-border-color-bottom);
  font-size: var(--print-text-size);

  img {
    object-fit: cover;
    width: 7rem !important;
    height: 7rem !important;
  }
}
