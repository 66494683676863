.full-page-print-column-height {
  height: 30rem;
}

.under-map-column-height {
  height: 26rem;
}

/* Targets all the pages */
// FOR PRINTING
@page {
  // size: 8.5in 9in;
  margin-top: 0.3in;
  margin-bottom: 0.3in;
  margin-left: 0.3in;
  margin-right: 0.3in;
}

/* Targets all even-numbered pages */
// @page :left {
//   margin-top: 4in;
// }

/* Targets all odd-numbered pages */
// @page :right {
//   // size: 11in;
//   margin-top: 4in;
// }

/* Targets all selectors with `page: wide;` set */
// @page wide {
//   size: a4 landscape;
// }

// @page {
//   /* margin box at top right showing page number */
//   @top-right {
//     content: "Page " counter(pageNumber);
//   }
// }
