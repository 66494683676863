.gm-dropdown-search {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow: scroll;
  background-color: white;
  color: black;
  margin-top: 10px;
  z-index: 1000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
}

.gm-dropdown-search ul {
  list-style: none;
  margin-top: 0;
  padding-left: 0;
}

.gm-dropdown-search li {
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
}

.gm-dropdown-search li span {
  margin-left: 25px;
}

.gm-dropdown-search li:nth-child(2n + 1) {
  background-color: #f8f8f8;
  position: relative;
  z-index: 100;
}

.gm-dropdown-search li:hover {
  box-shadow: 0 1px 4px 0 black !important;
  position: relative;
  z-index: 101;
}

.gm-loader-parent {
  padding-top: 5px;
}
