/* start new styling */

.headerText {
  font-size: calc(var(--apta-text-size) * 2);
  font-weight: calc(var(--apta-font-weight) * 3);
  margin: 0px;
}

@media screen and (min-width: 768px) {
  .gm-border-right {
    border-right: 1px solid #cccccc;
  }
  .gm-border-left {
    border-left: 1px solid #cccccc;
  }
}

.text-blue {
  color: #36a2eb !important;
}

.gm-home-page-details-bold {
  font-weight: calc(var(--apta-font-weight) * 6);
}

.gm-home-page-details-unbold {
  font-weight: calc(var(--apta-font-weight) * 3);
}

.gm-homepage-font-size-small {
  font-size: calc(var(--apta-text-size) * 1);
}

.doughnut-title {
  font-weight: calc(var(--apta-font-weight) * 3);
  margin-bottom: 15px;
}

/*end new styling */
.gm-home-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gm-full-fill {
  height: 100%;
}

.usMapImg {
  height: 340px !important;
  margin-top: 40px !important;
}
.slick-prev {
  left: 50px;
}
.slick-next {
  right: 50px;
}
.slick-dots {
  z-index: 1;
  bottom: 10px;
}
.slick-arrow {
  z-index: 1;
}
.slick-slider {
  height: 100%;
}
.slick-slide {
  height: 100%;
  margin: 0 0px;
}
.slick-list {
  height: 100%;
  margin: 0 -1px;
  background-color: #828282;
}
.slick-dots li button:before {
  color: white;
  font-size: 20px;
  opacity: 1;
}
.slick-dots li.slick-active button:before {
  color: #f2f2f2;
  opacity: 0.75;
}
.mainSlider {
  height: 635px;
  width: 100%;
  margin: 0;
  padding: 0px;
  float: left;
}
.gm-multiSlideshow {
  height: 300px;
}
.gm-multiSlideshow div {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.fcolor {
  color: black !important;
}

.div-color {
  background-color: lightblue;
  height: 300px !important;
}
.div-color2 {
  background-color: coral;
  height: 300px !important;
}
.footer {
  background: #f2f2f2;
  width: 100% !important;
}
.footerText {
  color: #555 !important;
}
.gm-section2 {
  background: #09162a;
}

.slide-img {
  width: 100% !important;
  height: auto;
  object-position: 0px -300px;
  position: relative !important;
}

.carousel .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100% !important;
}

.gm-text-carousel-container {
  width: 100%;
  position: absolute !important;
  /* bottom: 8px; */
  top: 25%;
  background-color: #201c1c !important;
  opacity: 0.8;
}

.gm-text-carousel-container h2 {
  font-size: 25px;
}

.text-white {
  color: white !important;
}
.text-dark-blue {
  color: #0079c1;
}

.gm-section-2-card-container .outline-card {
  box-shadow: 0px 0px 0px 0px;
  /* margin-left: 0px;
    margin-right: 0px; */
}
.gm-section-2-card-container .outline-card:nth-child(2) {
  border-left: solid 1px #cccccc;
  border-right: solid 1px #cccccc;
}

.gm-section-2-card-container-border {
  border-left: solid 1px #cccccc;
  border-right: solid 1px #cccccc;
}

.gm-section-5-card-container .card {
  box-shadow: 0px 0px 0px 0px;
}

.gm-section-5-card-container .card:nth-child(2) {
  border-left: solid 1px #cccccc;
  border-right: solid 1px #cccccc;
}

.gm-sub-search {
  margin-top: 0;
}

.gm-search-menu {
  margin-top: 5px;
}
.gm-member-text-left {
  padding-left: 20px;
  padding-top: 10px;
}
.gm-member-text-right {
  padding-right: 15px;
  padding-top: 10px;
}

.gm-tranSys-text {
  font-size: 80px;
  font-weight: 900;
}

.gm-funding-seb-text {
  font-size: 22px;
}

.gm-accordion-sub-text {
  font-size: 15px !important;
}

.gm-flex-funding-dough {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1500px) {
  .slide-img {
    object-position: 0 -200px;
  }
}

@media (max-width: 1250px) {
  .slide-img {
    object-position: 0 -100px;
  }
}

@media (max-width: 1025px) {
  .gm-tranSys-text {
    font-size: 60px;
    font-weight: 800;
  }
  .gm-funding-head-text {
    font-size: 22px;
  }
  .gm-funding-seb-text {
    font-size: 18px;
  }

  .slide-img {
    object-fit: cover;
    object-position: 0 0;
  }

  .gm-text-carousel-container {
    width: 100%;
    height: auto;
    position: absolute !important;
    /* bottom: 8px; */
    top: 25%;
    background-color: #201c1c !important;
    opacity: 0.8;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }
  .mainSlider {
    height: 300px;
  }
  .gm-text-carousel {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .gm-sub-text-carousel {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .slick-dots {
    bottom: 8px;
  }
  .slick-dots li button:before {
    font-size: 12px;
  }
  .gm-text-carousel-container {
    bottom: 34px;
  }
  .slide-img {
    width: 100% !important;
    height: auto;
    object-fit: fill;
    background-size: 100% 100%;
    position: relative !important;
  }
  .gm-tranSys-text {
    font-size: 50px;
    font-weight: 900;
  }
  .gm-funding-head-text {
    font-size: 22px;
  }
  .gm-funding-seb-text {
    font-size: 18px;
  }

  .gm-flex-funding-dough {
    display: flex;
    flex-direction: column;
  }

  .gm-section-2-card-container .card:nth-child(2) {
    border-left: solid 0px #cccccc;
    border-right: solid 0px #cccccc;
    border-bottom: solid 1px #cccccc;
    border-top: solid 1px #cccccc;
  }
}

/* Iphone 6/7/8 */
@media (max-width: 425px) {
  .mainSlider {
    height: 168px;
  }
  .gm-text-carousel-container {
    /* visibility: hidden !important; */
    height: 80px;
    bottom: 58px;
  }
  .gm-text-carousel {
    font-size: 16px;
  }
  .slide-img {
    width: 100% !important;
    height: auto;
    object-fit: fill;
    background-size: 100% 100%;
    position: relative !important;
  }
  .slick-arrow {
    visibility: hidden !important;
  }
  .gm-member-text-left {
    padding-left: 0;
    padding-top: 0;
  }
  .gm-member-text-right {
    padding-right: 0;
    padding-top: 0;
  }
  .gm-tranSys-text {
    font-size: 20px;
    font-weight: 900;
  }
  .gm-funding-head-text {
    font-size: 22px;
  }
  .gm-funding-seb-text {
    font-size: 18px;
  }
  .gm-border-right {
    border-right: 0;
  }
  .gm-border-left {
    border-left: 0;
  }
  .gm-text-carousel-container {
    opacity: 0.6;
  }

  .grid-container {
    clear: both !important;
  }

  .gm-flex-funding-dough {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .gm-border-right {
    border-width: 0;
  }
}
