.gm-slide-container {
  height: var(--apta-carousel-height);

  @media screen and (max-width: 600px) {
    height: calc(var(--apta-carousel-height) / 2);
  }
}

.gm-slide-text {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 10;
  color: var(--apta-carousel-text-color);
  background-color: #201c1c !important;
  opacity: 0.8;
  padding: 0 5% 0 5%;

  h1 {
    font-size: calc(var(--apta-text-size) * 3.5) !important;
    font-weight: calc(var(--apta-font-weight) * 7) !important;
    line-height: 1.1;
    padding-top: 24px;
  }

  p {
    font-size: calc(var(--apta-text-size) * 1.5) !important;
    font-weight: calc(var(--apta-font-weight) * 7) !important;
    margin: 0;
    padding-bottom: 24px;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 12%;
    h1 {
      font-size: calc(var(--apta-text-size) * 1.8) !important;
      font-weight: calc(var(--apta-font-weight) * 7) !important;
      padding-top: 10px;
    }

    p {
      font-size: calc(var(--apta-text-size) * 1.2) !important;
      font-weight: calc(var(--apta-font-weight) * 7) !important;
      margin: 0;
      padding-bottom: 10px;
    }
  }
}

.slick-dots {
  z-index: 1;
  bottom: 10px;
}

.slick-slider {
  height: 100%;
}
.slick-slide {
  height: 100%;
  margin: 0 0px;
}
.slick-list {
  height: 100%;
  margin: 0 -1px;
  background-color: #828282;
}

.slick-dots li {
  margin: 0 0 0 0;
  @media screen and (min-width: 992px) {
    margin: 0 5px;
  }
}

.slick-dots li button:before {
  color: white;
  font-size: 10px;
  opacity: 1;

  @media screen and (min-width: 992px) {
    font-size: 20px;
  }
}
.slick-dots li.slick-active button:before {
  color: #f2f2f2;
  opacity: 0.75;
}

.carousel-indicators {
  > li {
    border-radius: 50px !important;
    height: 15px !important;
    width: 15px !important;
    opacity: 1 !important;
    padding: 2px;
  }
  .active {
    opacity: 0.4 !important;
  }
}

.gm-homepage-carousel-image {
  overflow: hidden;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
