.spinner {
  margin: 100px auto;
  width: 70px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #7a003c;
  height: 70px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;

  color: #fff;
}

.spinner > div {
  background-color: #fff;
  height: 70%;
  width: 6px;
  display: inline-block;
  margin: 0 2px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner-text {
  color: #fff;
  font-size: 12px;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
