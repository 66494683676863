.gm-person-list{
  padding: 0; 
  width: 100%;
}

.gm-button-list-elem {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-top: 0;
  width: 100%;
}

.gm-button-list-elem-rest{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.gm-button-list-elem-img {
  width: 75px;
  height: auto;

  margin-right: 15px;
  margin-left: 10px;
}

.gm-person-list-elem {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 0;
  width: 100%;
  position: relative;
}

.gm-person-list-elem hr {
  margin: 0;
}

.gm-person-list-elem-content {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
}

.gm-person-list-elem-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink:  0;
  object-fit: cover;
}

.gm-person-list-elem-details {
  display: flex;
  flex-direction: column;
}

.gm-person-list-toggles {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  margin-right: 5px; 
}

.gm-person-list-toggles svg{
 
    margin-left: auto;
  }

  .gm-person-list-toggles .gm-printer-icon{
      display: flex;
      flex-direction: row;
    margin-left: auto;
  }

  .gm-person-list-toggles .gm-printer-icon p {
    margin-right: 5px; 
}

  
  
  .gm-district-span {
    display: flex;
    flex-direction: row;
  margin-left: auto;
    margin-top: auto;
    max-width: 225px;

  }
  



  .gm-unset-height{
    height: 75px!important;
  }