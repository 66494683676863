.gm-cart-button:hover {
  color: white !important;
  border-color: rgba(71, 49, 142, 1) !important;
  background-color: rgba(71, 49, 142, 1) !important;
}
.gm-cart-button {
  color: rgba(71, 49, 142, 1) !important;
  border-color: rgba(71, 49, 142, 1) !important;
  background-color: white !important;
  font-weight: 600 !important;
  padding-left: 75px !important;
  padding-right: 75px !important;
  border-radius: 10px !important;
  border-width: 2px !important;
  /* background-color: rgba(71, 49, 142, 1) !important; */




}

.gm-cart-button-invert {
  color: white !important;
  border-color: rgba(71, 49, 142, 1) !important;
  background-color: rgba(71, 49, 142, 1) !important;
  font-weight: 600 !important;
  padding-left: 75px !important;
  padding-right: 75px !important;
  border-radius: 10px !important;
}


@media (max-width: 425px) {

  .gm-cart-button {
    color: rgba(71, 49, 142, 1) !important;
    border-color: rgba(71, 49, 142, 1) !important;
    background-color: white !important;
    font-weight: 600 !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    border-radius: 10px !important;
    border-width: 2px !important;
    /* background-color: rgba(71, 49, 142, 1) !important; */
  
  }

  .gm-cart-button-invert {
    color: white !important;
    border-color: rgba(71, 49, 142, 1) !important;
    background-color: rgba(71, 49, 142, 1) !important;
    font-weight: 600 !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    border-radius: 10px !important;
  }

}