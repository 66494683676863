//global controlled classes

.gm-full-nav-links {
  color: var(--apta-navbar-text-color) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
  font-size: calc(var(--apta-text-size) * 1) !important;
  text-transform: uppercase;
  margin-left: 20px !important;
  border-bottom: 4px solid transparent;
  position: relative;

  @media screen and (max-width: 1075px) {
    font-size: 12px;
  }

  @media screen and (max-width: 873px) {
    font-size: 11px;
  }
}

.gm-navbar-hamburger {
  font-size: calc(var(--apta-text-size) * 2) !important;
  color: var(--apta-navbar-text-color) !important;
}

.apta-logo-white {
  height: calc(var(--apta-text-size) * 4) !important;
}

.navbar-text {
  color: var(--apta-navbar-text-color) !important;
}

.navbar-dark .nav-link:hover {
  border-bottom: 4px solid var(--apta-navbar-text-color);
}

.navbar-dark .nav-link:focus {
  border-bottom: 4px solid var(--apta-navbar-text-color);
}

.gm-main-header {
  width: 100%;
  background: var(--apta-navbar-color);
  border-bottom: 4px solid var(--apta-navbar-border-color);
}

.nav-item:hover {
  cursor: pointer;
}

.gm-navbar-icons {
}
//end globallly controlled variables

@media screen and (max-width: 992px) {
  .gm-header-dropdown {
    position: absolute;
    /* background-color: rgba(169, 169, 169, 0.8); */
    background-color: rgba(0, 58, 115, 1);
    width: 300px;
    height: 300px;
    padding: 10px;
    left: calc(50% - 300px) !important;
    top: 50px;
    z-index: 101;
    flex-direction: column;
  }
}

@media screen and (min-width: 993px) {
  .gm-header-dropdown {
    left: -200px;
    top: 50px;
  }
}

.gm-header-dropdown {
  position: absolute;
  display: flex;
  /* background-color: rgba(169, 169, 169, 0.8); */
  background-color: rgba(0, 58, 115, 1);
  width: 300px;
  height: 300px;
  padding: 10px;
  border-radius: 10px;
  z-index: 101;
  flex-direction: column;
}

.gm-header-dropdown h6 {
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 6px;
  text-align: center;
  font-weight: 700;
  color: white;
}

.gm-header-dropdown p {
  font-size: 0.8rem;
  text-align: center;
  font-weight: 200;
  margin-bottom: 0.5rem;
  text-transform: none;
  color: white;
  letter-spacing: -1px;
  padding-left: 9.8px;
  padding-right: 9.8px;
}

.gm-header-dropdown input {
  margin-top: 0px;
  margin-left: 10px;
  width: 90%;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.gm-clipboard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.gm-clipboard input {
  width: 70%;
  margin-right: 8px;
  margin-bottom: 5px;
}

.gm-clipboard svg {
  width: 25px;
  height: 25px;
  color: white;
  fill: white;
}

.gm-copy-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.gm-copy-group svg {
  width: 45px;
  height: 25px;
  color: #005e95;
}

@media print {
  .gm-header-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
  }

  .gm-print-NONE {
    display: none;
  }
}

@media screen and (max-width: 380px) {
  .tablet-apta-logo {
    height: 50px;
  }
  .hamburger-link {
    font-size: 20px;
  }
}
