@import "./Vars.scss";

.gm-collapsible-header-title {
  cursor: pointer;

  h1 {
    margin: 0;
    font-size: $PanelHeaderFontSize;
  }

  div:first-of-type {
    margin: auto 0 auto auto;
  }
}
