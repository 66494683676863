body {
  -webkit-print-color-adjust: exact !important;
}

.chart-canvas {
  width: 100% !important;
  // height: 100% !important;
  margin-top: auto;
  margin-bottom: auto;
}

.gm-section-header {
  height: "100%";
  border-bottom: solid 1px black;
  // margin-bottom: ($spacer * .5);

  &-full {
    height: "100%";
    border-bottom: solid 1px black;
  }

  &-postcard {
    height: "100%";
    border-bottom: solid 1px black;
  }
}

.gm-subitem-img {
  width: 5rem;
  height: 5rem;
  &-postcard {
    width: 7rem;
    height: 7rem;
  }
}

.gm-subitem {
  width: 0rem;
  height: 5rem;
  &-postcard {
    width: 0rem;
    height: 7rem;
  }
}

.gm-explore-header {
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 2);
  line-height: calc(var(--apta-text-size) * 2) !important;
  font-weight: calc(var(--apta-font-weight) * 3);
  margin: 0;

  &-full {
    color: var(--atpa-color-primary) !important;
    font-size: calc(var(--apta-text-size) * 2);
    line-height: calc(var(--apta-text-size) * 2) !important;
    font-weight: calc(var(--apta-font-weight) * 3);
    margin: 0;
  }

  &-postcard {
    color: var(--atpa-color-primary) !important;
    font-size: calc(var(--apta-text-size) * 3) !important;
    line-height: calc(var(--apta-text-size) * 3) !important;
    font-weight: calc(var(--apta-font-weight) * 3) !important;
  }
}

.gm-explore-numeric {
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 2) !important;
  line-height: calc(var(--apta-text-size) * 2) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;

  margin: 0;

  &-full {
    color: var(--apta-numeric-primary) !important;
    font-size: calc(var(--apta-text-size) * 2) !important;
    line-height: calc(var(--apta-text-size) * 2) !important;
    font-weight: calc(var(--apta-font-weight) * 5) !important;

    margin: 0;
  }

  &-postcard {
    color: var(--apta-numeric-primary) !important;
    font-size: calc(var(--apta-text-size) * 3) !important;
    line-height: calc(var(--apta-text-size) * 3) !important;
    font-weight: calc(var(--apta-font-weight) * 5) !important;
  }
}

.gm-explore-numeric-2 {
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 2) !important;
  line-height: calc(var(--apta-text-size) * 2) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;

  margin: 0;

  &-full {
    color: var(--apta-numeric-primary) !important;
    font-size: calc(var(--apta-text-size) * 2) !important;
    line-height: calc(var(--apta-text-size) * 2) !important;
    font-weight: calc(var(--apta-font-weight) * 5) !important;

    margin: 0;
  }

  &-postcard {
    color: var(--apta-numeric-primary) !important;
    font-size: calc(var(--apta-text-size) * 2) !important;
    line-height: calc(var(--apta-text-size) * 2) !important;
    font-weight: calc(var(--apta-font-weight) * 5) !important;
  }
}

.gm-explore-numeric-3 {
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;

  margin: 0;

  &-full {
    color: var(--apta-numeric-primary) !important;
    font-size: calc(var(--apta-text-size) * 1.5) !important;
    line-height: calc(var(--apta-text-size) * 1.5) !important;
    font-weight: calc(var(--apta-font-weight) * 5) !important;

    margin: 0;
  }

  &-postcard {
  }
}

.gm-explore-numeric-4 {
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 1) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;

  margin: 0;

  &-full {
    color: var(--apta-numeric-primary) !important;
    font-size: calc(var(--apta-text-size) * 1) !important;
    line-height: calc(var(--apta-text-size) * 1.5) !important;
    font-weight: calc(var(--apta-font-weight) * 5) !important;

    margin: 0;
  }

  &-postcard {
    color: var(--apta-numeric-primary) !important;
    font-size: calc(var(--apta-text-size) * 1.1) !important;
    line-height: calc(var(--apta-text-size) * 1.5) !important;
    font-weight: calc(var(--apta-font-weight) * 5) !important;

    margin: 0;
  }
}

.gm-explore-text {
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;

  margin: 0;

  &-full {
    color: var(--atpa-color-primary) !important;
    font-size: calc(var(--apta-text-size) * 1.5) !important;
    line-height: calc(var(--apta-text-size) * 1.5) !important;
    font-weight: calc(var(--apta-font-weight) * 5) !important;

    margin: 0;
  }

  &-postcard {
    color: var(--atpa-color-primary) !important;
    font-size: calc(var(--apta-text-size) * 2) !important;
    line-height: calc(var(--apta-text-size) * 2) !important;
    font-weight: calc(var(--apta-font-weight) * 5) !important;
    margin-bottom: 0;
  }
}

.gm-explore-text-2 {
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 4) !important;

  margin: 0;

  &-full {
    color: var(--atpa-color-primary) !important;
    font-size: calc(var(--apta-text-size) * 1.5) !important;
    line-height: calc(var(--apta-text-size) * 1.5) !important;
    font-weight: calc(var(--apta-font-weight) * 4) !important;

    margin: 0;
  }

  &-postcard {
    color: var(--atpa-color-primary) !important;
    font-size: calc(var(--apta-text-size) * 2) !important;
    line-height: calc(var(--apta-text-size) * 2) !important;
    font-weight: calc(var(--apta-font-weight) * 5) !important;
    margin-bottom: 0;
  }
}

.gm-explore-text-3 {
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 1) !important;
  font-weight: calc(var(--apta-font-weight) * 4) !important;

  margin: 0;

  &-full {
    color: var(--atpa-color-primary) !important;
    font-size: calc(var(--apta-text-size) * 1) !important;
    font-weight: calc(var(--apta-font-weight) * 4) !important;

    margin: 0;
  } 

  &-postcard {
    color: var(--atpa-color-primary) !important;
    font-size: calc(var(--apta-text-size) * 1.2) !important;
    font-weight: calc(var(--apta-font-weight) * 4) !important;
  }
}

.gm-explore-subtext {
  color: var(--apta-color-secondary) !important;
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  line-height: calc(var(--apta-text-size) * 2) !important;
  font-weight: calc(var(--apta-font-weight) * 2) !important;

  margin: 0;

  &-full {
    color: var(--apta-color-secondary) !important;
    font-size: calc(var(--apta-text-size) * 1.5) !important;
    line-height: calc(var(--apta-text-size) * 1.5) !important;
    font-weight: calc(var(--apta-font-weight) * 2) !important;

    margin: 0;
  }

  &-postcard {
  }
}

.gm-explore-subtext-legend {
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 1) !important;
  line-height: calc(var(--apta-text-size) * 1.2) !important;
  font-weight: calc(var(--apta-font-weight) * 2) !important;

  margin: 0;

  &-full {
    color: var(--atpa-color-primary) !important;
    font-size: calc(var(--apta-text-size) * 1) !important;
    line-height: calc(var(--apta-text-size) * 1.2) !important;
    font-weight: calc(var(--apta-font-weight) * 2) !important;

    margin: 0;
  }

  &-postcard {
  }
}

.gm-explore-image {
  height: 6px;
}

.gm-member-summay-section-text {
  font-size: calc(var(--apta-text-size) * 1) !important;
  color: var(--apta-numeric-primary);
}

.gm-member-summay-text {
  font-size: calc(var(--apta-text-size) * 1) !important;
  color: var(--apta-color-secondary);
}

@media print {
  canvas.chart-canvas {
    display: flex;
    // min-height: 100%;
    max-width: 100%;
    // max-height: 100%;
    height: auto !important;
    width: auto !important;
  }
}
