@import "./variables.scss";

.gm-loading-div-above {
  width: 100%;
  height: 25px;
  text-align: center;
  p {
    font-weight: bold;
    margin: 0 !important;
    text-align: center;
    padding: 0 !important;
  }
}

.gm-cart-header {
  p {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 10px;
  }
}

.gm-zip-loading {
  p {
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.gm-zip-loading {
  p {
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.gm0 .gm-cart-parent {
  height: 100%;
}

.gm-cart-content {
  background: white;
  width: 100%;

  .alert {
    z-index: 1;
  }
}

#gm-cart-parent {
  @extend %flex-column;
  margin: 0;
  padding-left: 12px;
}

#gm-cart-header {
  @extend %flex-row;
  margin: 0;
  padding-bottom: 2px;

  p {
    margin: 0;
  }
}

#gm-cart-header h1 {
  @extend %flex-row;
  margin: 0;
}

#gm-cart-header-end {
  @extend %flex-row;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 0.75rem;
}

#gm-cart-header-end :hover {
  cursor: pointer;
}

#gm-cart-header-end p {
  margin: 0;
  margin-right: 10px;
  margin-left: 10px;
}

.gm-cart-buttons {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  button {
    margin-left: 5px;
  }
  margin-bottom: 15px;
}

.gm-button-div {
  display: flex;
  height: 100%;
  margin-left: auto;
}

#gm-cart-header-svg {
  width: 25px;
  height: 25px;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.gm-layer-section {
  overflow: wrap;
}

.gm-layer-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: flex-start;
}

.gm-layer-toggle {
  display: flex;
  min-width: 250px;
  margin-left: 10px;

  margin-top: 10px;
  background-color: #efefef;
  border: 1px solid #ccc;
  justify-content: flex-start;
  align-items: center;
  // background-color:  grey;

  // color: red;
}

.gm-back-button-svg {
  width: 35px;
  height: 35px;
}

.gm-map-layers-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.gm-map-layers-container :hover {
  background: #efefef;
  cursor: pointer;
}

.gm-map-layers-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    font-size: 24px;
    margin: 0;
  }

  p {
    margin-left: auto;
    margin: 0;
    margin-right: auto;
  }
}

.gm-map-header-left {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: auto;

  h1 {
    font-size: 24px;
    margin: 0;
  }

  span {
    margin-right: 15px;
    font-size: 12px;
  }
}

.gm-map-layers-svg {
  width: 25px;
  height: 25px;
  margin-left: auto;
}
