.gm-person-list-elem {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 0;
  width: 100%;
  position: relative;
}

.gm-person-list-elem hr {
  margin: 0;
}

.gm-person-list-elem-content {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
}

.gm-person-list-elem-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  flex-shrink:  0;
}

.gm-person-list-elem-details {
  display: flex;
  flex-direction: column;
}

.gm-person-list-toggles {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  margin-right: 5px; 
}

.gm-person-list-toggles svg{
  
    margin-left: auto;
  }

  .gm-person-list-toggles .gm-printer-icon{
      display: flex;
      flex-direction: row;
    margin-left: auto;
  }

  .gm-person-list-toggles .gm-printer-icon p {
    margin-right: 5px; 
}

  
  
  .gm-district-span {
    display: flex;
    flex-direction: row;
  margin-left: auto;
    margin-top: auto;

  }
  

