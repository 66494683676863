.gm-state-image-container {
  display: flex;
  height: 65px;
  width: 77px;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
}



.gm-state-image {
  width: 75px;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid rgba(0, 0, 0 ,.8);
  object-fit: cover;

}

.gm-rhode-island-img {
  width: 65px !important;
}

.gm-auto-height{
  height: auto;
}

.gm-no-border{
  border: none;
}

.gm-person-chevron{
  width: 20px;
  height: 20px;
  align-self: flex-end;
  line-height: 1;
}