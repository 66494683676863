.gm-data-section {
  width: 700px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 699px) {
    width:100%;
    .block-group {
      padding: 5px;
    }
  }
}

.gm-major-grant-section {
  .gm-print-section-full {
    width: 720px;
    overflow-x: hidden;

    @media screen and (max-width: 719px) {
      height: 650px;
      width:100%;
      h1 {
        width: 350px;
      }
    }
  }
}

.gm-data-summary-section {
  .gm-print-section-full {
    height: 340px;
    width: 720px;
    overflow-x: hidden;

    @media screen and (max-width: 719px) {
      h1 {
        width: 350px;
      }
      height: 400px;
      width: 100%;
    }
  }
}
