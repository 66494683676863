.gm-v2-panel-header {
  height: 95px;

  background: #e8e8e8;
  z-index: 50;

  flex-shrink: 0;
  width: 100%;
}

.gm-v2-panel-header-content {
  // @extend %flex-row;
  // position: relative;

  // height: 100%;
  // justify-content: center;
  // align-items: center;
}

.gm-v2-panel-header-buttons {
  display: flex;
  height:100%;

  margin-left: 0;
}
