@import './CartTable.scss';

.gm-cart-header {

    display: flex;

    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
    h1 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0;
    }
}

.gm-cart-loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}