// @import "../../node_modules/bootstrap//scss/variables";
@import "./variables.scss";
@import "./Print//PrintVariables.scss";

@import "./Cart.scss";
@import "./Header.scss";
@import "./ListElem.scss";
@import "./PersonListElem.scss";
@import "./Spinners/LoadingSpinner.scss";

@import "./Carousel.scss";
@import "./Home/Search.scss";
@import "./Panel/index.scss";
@import "./Map/index.scss";
@import "./Cart/PrintSelect.scss";
@import "./Cart/index.scss";
@import "./Explore/Explore.scss";
@import "./v2/Panel.scss";
@import "./StandardPrint/index.scss";
@import "./Print/Postcard.scss";

html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: block;
  /* color: #4c4c4c !important; */
  /* code below doesn't work for many font-weights */
  /* font-family: "Avenir Next", "Avenir", "Helvetica Neue", sans-serif !important; */
  /* font-size: 1rem !important; */
}

#root {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

#gm-print-view-div {
  height: 500px;
}

.gm-pos-relative {
  position: relative;
  height: 100%;
}

.gm-error-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(169, 169, 169, 0.8);
  z-index: 50000;
}

.gm-error-message {
  display: flex;
  background-color: #7a003c;
  height: 200px;
  width: 400px;
  padding: 15px;
  color: white;
  font-size: 25px;
  text-align: center;

  z-index: 105;

  position: absolute;
  top: 33vh;
  left: 50%;
  transform: translate(-50%, -33%);
}
.gm-error-message p {
  padding-top: 30px;
  margin: 0;
  height: 100%;
  display: flex;
}

.gm-display-none {
  display: none;
}

.esriSignInDialog {
  display: none;
}

.gm-blue-h2 {
  font-size: 45px;
  font-weight: 900;
  color: #36a2eb;
}

.gm-red-text {
  color: var(--apta-red-text) !important;
}

.gm-blue-text {
  color: var(--apta-navbar-text-color);
}

.form-control.is-valid {
  /* border-color: var(--apta-navbar-text-color) !important; */
}

.gm-basic-button {
  padding: 5px 25px;
  border-radius: 30px;
  border: none;
}

.gm-clearfix {
  clear: both;
}

.esri-collapse__icon::before {
  padding: 0 !important;
}

.gm-first-row-header {
  padding-bottom: 20px;
}
.gm-first-row-header2 {
  padding-bottom: 18px;
}

.gm-first-row-header3 {
  padding-bottom: 35px;
}

.gm-rounded {
  border-radius: 50%;
}

#searchInputWrap {
  position: relative;
}

.gm-side-panel-content {
  margin: 0px 0px 0px 20px;
  padding-right: 10px;

  // @media screen and (max-width: 360px) {
  //   width: 350px;
  //   margin: 0px 0px 0px 10px;
  // }

  @media screen and (max-width: 699px) {
    width: 100%;
    margin: 0;
    padding: 0px 10px 0px 10px;
    box-sizing: border-box;
  }
}

/* Fed List Elem */

.gm-fed-list-elem {
  width: 100%;
  margin-top: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.gm-fed-list-elem-content {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.gm-fed-list-elem-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 15px;
  margin-left: 10px;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.gm-overflow {
  overflow: hidden;
}

.esri-legend__layer-caption {
  display: none !important;
}

.dropdown-link:focus {
  outline-style: none;
  border-left: 0px !important;
}

.gm-dropdown-layer-on {
  // position: absolute;
  // top: 0;
  // right: 25px;
  display: flex;
  flex-direction: row;
}

.gm-dropdown-layer-off {
  // position: absolute;
  // top: 0.25rem;
  // right: -0.1rem;
  display: flex;
  flex-direction: row;
}

.gm-map-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #7a003c;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  max-width: 75%;

  color: #fff;
}

.esri-view-height-xsmall .esri-expand .esri-widget--panel {
  max-height: 100%;
}

// setting this to 55% height somehow reduces the width of the page
// .esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
//   height: 55%;
// }
