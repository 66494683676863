.gm-panel-list {
  padding: 0;
}

.gm-panel-list-elem {
  position: relative;
  margin: 0;
  padding: 10px 0 10px 0;
  list-style: none;
  border-bottom: 1px solid #a7a7a7;

  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 699px) {
    width: 100%;
  }
}

.gm-collapsible-header-title {
  h1 {
    font-weight: 300;
  }
}

.gm-panel-list-elem:hover {
  background-color: rgba(167, 167, 167, 0.1);
}

.gm-panel-list-elem-children {
  width: 100%;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
}

.gm-state-span-icon-wrapper {
  margin-left: auto;
}

.gm-li-state-span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gm-li-state-chevron-wrapper {
  margin-top: 5px;
  margin-left: auto;
}
