.gm-format-card{
    width: 150px;
    cursor: pointer;
  }
  

.gm-format-card-active{
    border: 2px solid rgb(54, 162, 235)!important;
    background-color: rgb(54, 162, 235, .05) !important;
  }
  

.gm-format-card-body{
  display: flex !important;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
}
