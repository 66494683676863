.gm-search-panel {
  section {
    padding: 10px 0 10px 0;
  }
}

.gm-search-panel-tabs {
  display: flex;
  flex-direction: row;
}

.gm-search-panel-tab {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.gm-search-panel-tab:hover {
  cursor: pointer;
}

.gm-search-panel-tab-active {
  background-color: #003a72;
  color: white;
}

.gm-search-panel-results {
  p{
    text-align: center;
  }
}
