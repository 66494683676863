@import "./DataSection.scss";

#mapContainer {
  display: flex;
  flex-direction: row;
  position: relative;
}

#gm-side-panel-parent {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 750px;
  overflow: hidden;
  flex-shrink: 0;

  @media screen and (max-width: 499px) {
    width: 100%;
    height: 500px;
  }
}

.gm-side-panel-content {
  overflow-y: scroll;
}

.gm-map-section {
  height: 750px;
  width: 100%;
  flex-grow: 1;
  background-color: #1d2223;
  display: flex;
  justify-content: center;
  align-items: center;

  //remove this if problem on mobile
  position: relative;
  @media screen and (max-width: 499px) {
    height: 500px;
  }
}

.make-display-none {
  display: none !important;
}

.make-this-transparent {
  // background-color: #1d2223;
  height: 100%;
  width: 100%;
  z-index: 100;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.make-this-transparent-inactive {
  // background-color: #1d2223;
  height: 100%;
  width: 100%;
  z-index: 100;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 550px) {
    display: none;
  }
}

#viewDiv {
  height: 100%;
  width: 100%;
  position: absolute;
}

.gm-hide {
  width: 0 !important;
}

.gm-slide-toggle {
  position: absolute;
  top: 381px;
  z-index: 1 !important;
  left: 500px;
  width: 25px;
  height: 45px;
  line-height: 45px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24);
  z-index: 0;
  cursor: pointer;
  padding: 0 0 0 5px;
  border-radius: 2px;

  //   @media screen and (max-width: 360px) {
  //     left: 330px;
  //   }

  @media screen and (max-width: 699px) {
    left: initial;
    right: 0;
    top: 381px;
  }
}

.gm-slide-toggle-hide-sidePanel {
  position: absolute;
  z-index: 1000 !important;
  top: 381px;
  left: 0px;
  width: 25px;
  height: 45px;
  line-height: 45px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24);
  z-index: 1;
  cursor: pointer;
  transform: rotate(180deg);
  padding: 1px 0 0 5px;
  border-radius: 2px;
}
