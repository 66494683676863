@import "./variables.scss";

.gm-panel-back-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gm-side-panel-header {
  @extend %flex-row;
  background: #e8e8e8;
  // padding: $panel-margin $panel-margin $panel-margin $panel-margin;
  // justify-content: center;
  align-items: center;
  height: 95px;
  z-index: 50;
  flex-shrink: 0;

  // @media screen and (max-width: 360px) {
  //   width: 360px;
  // }

  // @media screen and (max-width: 411px) {
  //   width: 360px;
  // }
}

.gm-side-panel-header-content {
  @extend %flex-row;
  position: relative;
  margin-left: $panel-margin;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.gm-side-panel-header-icon {
  display: flex;
  width: 26px;
  height: 100%;
  align-items: center;
}

.gm-side-panel-header-icon svg {
  width: 25px;
  height: 25px;
}

.gm-print-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: auto;
  max-width: 38px;
  margin-left: auto;

  svg {
    width: 25px;
    margin-left: 5px;
  }
}

.gm-side-panel-nav {
  font-size: 20px;
  line-height: 1.3;
  margin-left: $panel-margin;
  margin-right: $panel-margin;
}
.gm-side-panel-nav-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.gm-atlas-icon {
  background: var(--apta-side-panel-blue);
  width: 75px;
  margin-left: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  flex-shrink: 0;
}

.gm-seperator {
  width: 100%;
  height: 1px;
  background-color: white;
}

.gm-atlas-icon img {
  background: #003a73;
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.gm-panel-print-icon {
  width: 30px;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  position: relative;

  span {
    position: absolute;
    color: white;
    right: -20px;
    top: -8px;
    font-size: 12px;
    text-align: left;
    min-width: 20px;
  }

  svg {
    color: white;
    fill: white;
  }
}

.gm-panel-print-icon-active {
  border-bottom: 2px solid white;
  padding-bottom: 7px;
}

.gm-cart-count-panel {
  color: white;
  position: absolute;
  left: 32px;
  font-size: 10px;
}
