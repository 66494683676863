.gm-search-parent {
  width: 100%;
  background-color: var(--apta-search-background-home);
  clear: both;
  height: calc(var(--apta-text-size) * 8);
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: var(--apta-navbar-text-color);
    margin: 0;
  }
}

.gm-search-bar {
  ::-webkit-input-placeholder {
    /* Edge */
    color: rgb(28, 28, 28);
    // color: var(--apta-navbar-text-color);
  }

  :-ms-input-placeholder {
    /* Internet Explorer */
    color: rgb(28, 28, 28);
    // color: var(--apta-navbar-text-color);
  }

  ::placeholder {
    color: black;
    // color: var(--apta-navbar-text-color);
  }

  .gm-search-clear {
    color: var(--apta-navbar-text-color);
    position: absolute;
    top: 5px;
    right: 0px;
    cursor: pointer;
    padding-right: 0.75rem;
  }

  .gm-input {
    color: rgb(28, 28, 28);
    margin: 0;
    font-size: calc(var(--apta-text-size) * 1.75) !important;
    font-weight: calc(var(--apta-font-weight) * 2);

    background-color: white;
    border: none;
    box-shadow: none;
    // border-bottom: 3px solid white;
    // border-bottom: 3px solid var(--apta-navbar-text-color);

    &::placeholder {
      font-size: 1.4rem;
      position: absolute;
      top: 50%;
      // left: 50%;
      transform: translate(0%, -50%);
    }
  }

  .gm-input:focus {
    color: rgb(28, 28, 28);
  }
}
