@import "../variables.scss";

$dark-blue: #0661a0;
$light-blue: #36a2eb;

:root {
  --print-numeric-primary: var(--apta-numeric-primary);
  --print-blue-text: var(--apta-blue-text);
  --print-side-panel-blue: var(--apta-side-panel-blue);
  // --print-side-panel-blue: #36a2eb;
  --print-color-primary: var(--apta-color-primary);
  --print-color-secondary: var(--apta-color-secondary);
  --print-font-weight: 100; //multiplied by 1-9 in scss
  --print-text-size: 1em;
  // --print-navbar-color: #7a003c;
  --print-navbar-color: #f4f7f6;
  // --print-navbar-border-color: #f16627;
  --print-navbar-border-color-bottom: var(--apta-navbar-border-color);
  --print-navbar-border-color-top: #0661a0;
  // --print-navbar-text-color: white;
  --print-navbar-text-color: var(--apta-navbar-text-color);
  --print-carousel-text-color: var(--apta-carousel-text-color);
  --print-carousel-height: 45rem;
  --print-search-background: var(--apta-search-background);
  --print-search-background: var(--apta-search-background);
  --print-red-text: var(--apta-red-text);
}
