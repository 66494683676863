@media print {
  * {
    transition: none !important;
  }

  div {
    break-inside: avoid;
  }
}

.gm-standard-print-header {
  border-top: 4px solid var(--print-navbar-border-color-top);
  border-bottom: 4px solid var(--print-navbar-border-color-bottom);
  font-size: var(--print-text-size);

  // img {
  //   object-fit: cover;
  //   width: 3rem !important;
  //   height: 3rem !important;
  // }
}

.gm-header-person {
  img {
    object-fit: cover;
    width: 5rem !important;
    height: 5rem !important;
  }
}

.gm-header-logo {
  img {
    object-fit: cover;
    width: 3.5rem !important;
    height: 3.5rem !important;
  }
}

.gm-invis {
  display: none;
}

@media screen {
  .gm-print-helper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
  .gm-print-page {
    width: 700px;
  }
}

.gm-no-data {
  width: 100%;
  p {
    text-align: center;
    font-weight: normal;
    font-size: 28px;
    margin-top: 25px;
    color: $light-blue;
  }
}

.gm-print-page {
  orphans: 3;
}

.gm-data-summary-item {
  font-weight: bold;
  display: inline-block;
  width: 350px;

  margin-bottom: 5px;

  p {
    text-align: center;
  }

  p:first-of-type {
    color: #4c4c4c;
    margin: 0;
    margin-bottom: 3px;
    font-size: 14px;
  }

  p:nth-of-type(2) {
    color: $light-blue;
    margin: 0;
    font-size: 20px;
  }
}

.gm-legend-column {
  h1 {
    font-size: calc(var(--apta-text-size) * 1.5) !important;
    line-height: calc(var(--apta-text-size) * 1.5) !important;
    font-weight: calc(var(--apta-font-weight) * 2) !important;
  }

  h3 {
    font-size: 10px;
  }
}

.gm-map-legend-container {
  display: flex;
  flex-wrap: wrap;
}

.gm-map-legend-layer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 30%;

  // h1 {
  //   // font-size: 12px;
  //   // margin: 0;
  //   // padding: 0;
  //   // border: none;
  //   // height: unset();
  //   // font-weight: 900;
  //   font-size: calc(var(--apta-text-size) * 1.5) !important;
  //   line-height: calc(var(--apta-text-size) * 1.5) !important;
  //   font-weight: calc(var(--apta-font-weight) * 2) !important;
  // }
}

.gm-map-legend-layer {
  //   width: 175px;
  //   display: inline-block;
  //   vertical-align: top;
  //   h1 {
  //     font-size: 12px;
  //     margin: 0;
  //     padding: 0;
  //     border: none;
  //     height: unset();
  //     font-weight: 900;
  //   }
  //   margin-bottom: 10px;
}

.gm-map-legend-elem {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    // font-size: 10px;
    margin: 0;
    display: inline;
    margin-left: 5px;
    // max-width: 100px;
  }
  div {
    display: inline;
  }
}
