.gm-apta-member-section {
    margin: 0 15px 0 15px;
    p {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    td {
        min-width: 175px;
    }
}