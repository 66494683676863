$grey-background: #f3f3f3;

$panel-margin: 12px;

%flex-row {
  display: flex;
  flex-direction: row;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

:root {
  --apta-numeric-primary: #36a2eb;
  --apta-blue-text: #36a2eb;
  --apta-side-panel-blue: #003a73;
  // --apta-side-panel-blue: #36a2eb;
  --apta-color-primary: black;
  --apta-color-secondary: #6c757d;
  --apta-font-weight: 100; //multiplied by 1-9 in scss
  --apta-text-size: 1rem;
  // --apta-navbar-color: #7a003c;
  --apta-navbar-color: #f4f7f6;
  // --apta-navbar-border-color: #f16627;
  --apta-navbar-border-color: #d21729;
  // --apta-navbar-text-color: white;
  --apta-navbar-text-color: #33287c;
  --apta-carousel-text-color: #f4f7f6;
  --apta-carousel-height: 35rem;
  --apta-search-background-home: #36a2eb;
  --apta-search-background: #f4f7f6;
  --apta-red-text: #d21729;
}
