
.gm-cart-select-header {
    padding-bottom: 10px;
    padding-top: 10px; 
    border-bottom: 1px solid gray; 
    cursor: pointer;
}

.gm-cart-select-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0;
    }
}

.gm-cart-select-header-left {
    margin-left: auto;
}

.gm-cart-select-elem-container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.gm-cart-select-elem {
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    margin-bottom: 15px;
    cursor: pointer; 

    padding: 10px;

    h1 {
        font-size: 15px;
        margin: 0;
    }

    p {
        font-size: 15px;
    }
}

.gm-cart-select-elem h1 {}