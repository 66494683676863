.gm-person-panel-table{

    width: 100%;
    tr{
        td:first-of-type{
            width: 35%;
        }

        td:nth-of-type(2){
            text-align:middle;
        }
    }
}