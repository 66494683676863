.gm-transBox-count {
  font-size: calc(var(--apta-text-size) * 5);
  font-weight: calc(var(--apta-font-weight) * 6);
}

@media (max-width: 768px) {
  .gm-transBox-count {
    font-size: calc(var(--apta-text-size) * 3);
  }
}

.text-purple {
  color: #7a003b !important;
}

.text-orange {
  color: #ff5e15 !important;
}
