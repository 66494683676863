.cart-tooltip {
  position: relative;
  display: inline-block;
}

.cart-tooltip .cart-tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
  font-size: 12px;
  opacity: 0.7;
}

.cart-tooltip:hover .cart-tooltiptext {
  visibility: visible;
}
