
.gm-search-tab-container{
  display: flex; 
  flex-direction: row;
}

.gm-search-no-data {
  width: 100%;
  height: 400px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.gm-panel-tab {
  padding-top: 10px;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.gm-tab {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.gm-tab:hover {
  cursor: pointer;
}

.gm-active {
  background-color: #003a72;
  color: white;
}
